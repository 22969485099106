import {
  mdiAccountCheckOutline, mdiAccountCogOutline,
  mdiAccountOutline,
  mdiCity,
} from '@mdi/js'

export default [
  {
    subheader: 'CONFIGURATIONS',
  },
  {
    title: 'Data',
    icon: mdiAccountOutline,
    /*resource: 'Configuration',*/
    /*action: 'read',*/
    children: [
      {
        title: 'Specialties',
        to: 'specialties',
        resource: 'Specialty',
        action: 'read',
      },
      {
        title: 'Activities',
        to: 'activities',
        resource: 'Activities',
        action: 'read',
      },
    ],
  },
  {
    title: 'Location',
    icon: mdiCity,
    children: [
      {
        title: 'Divisions',
        to: 'divisions',
        resource: 'Division',
        action: 'read',
      },
      {
        title: 'Quarters',
        to: 'quarters',
        resource: 'Quarter',
        action: 'read',
      },
      {
        title: 'Cities',
        to: 'cities',
        resource: 'City',
        action: 'read',
      },
      {
        title: 'Zones',
        to: 'zones',
        resource: 'Zone',
        action: 'read',
      },
    /*  {
        title: 'Shipping categories',
        to: 'shippings',
        resource: 'Shipping',
        action: 'read',
      },
      {
        title: 'Transporters',
        to: 'transporters',
        resource: 'Transporter',
        action: 'read',
      },
      {
        title: 'Tariffs',
        to: 'tariffs',
        resource: 'Tariff',
        action: 'read',
      },*/
    ],
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'users',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Roles',
    icon: mdiAccountCogOutline,
    to: 'roles',
    resource: 'Role',
    action: 'read',
  },
  {
    title: 'Permissions',
    icon: mdiAccountCheckOutline,
    to: 'permissions',
    resource: 'Permission',
    action: 'read',
  },
]
