const apps = [
  {
    path: '/authors/:id',
    name: 'author-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/authors',
    name: 'authors',
    component: () => import('@/views/apps/author/author-list/Authors.vue'),
    meta: {
      layout: 'content',
      resource: 'Author',
    },
  },
  {
    path: '/adresses',
    name: 'address',
    component: () => import('@/views/apps/adresses/Adresses.vue'),
    meta: {
      layout: 'content',
      resource: 'Address',
    },
  },
  {
    path: '/packages/:id',
    name: 'packages-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Package',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/apps/products/Products.vue'),
    meta: {
      layout: 'content',
      resource: 'Product',
    },
  },
  {
    path: '/carts',
    name: 'carts',
    component: () => import('@/views/apps/carts/Carts.vue'),
    meta: {
      layout: 'content',
      resource: 'Cart',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/apps/orders/Orders.vue'),
    meta: {
      layout: 'content',
      resource: 'Order',
    },
  },
  {
    path: '/rides',
    name: 'rides',
    component: () => import('@/views/apps/rides/Rides.vue'),
    meta: {
      layout: 'content',
      resource: 'Ride',
    },
  },

  {
    path: '/demands',
    name: 'demands',
    component: () => import('@/views/apps/demands/Demands.vue'),
    meta: {
      layout: 'content',
      resource: 'Demand',
    },
  },
  {
    path: '/tests',
    name: 'tests',
    component: () => import('@/views/apps/tests/Tests.vue'),
    meta: {
      layout: 'content',
      resource: 'Test',
    },
  },
  {
    path: '/manuscripts/:id',
    name: 'manuscript-view',
    component: () => import('@/views/apps/author/author-view/AuthorView.vue'),
    meta: {
      layout: 'content',
      resource: 'Manuscript',
    },
  },
  {
    path: '/manuscripts',
    name: 'manuscripts',
    component: () => import('@/views/apps/manuscript/Manuscripts.vue'),
    meta: {
      layout: 'content',
      resource: 'Manuscript',
    },
  },
  {
    path: '/publications',
    name: 'publications',
    component: () => import('@/views/apps/publications/Publications.vue'),
    meta: {
      layout: 'content',
      resource: 'Publication',
    },
  },
  {
    path: '/quotations',
    name: 'quotations',
    component: () => import('@/views/apps/manuscript/Manuscripts.vue'),
    meta: {
      layout: 'content',
      resource: 'Cautation',
    },
  },
  {
    path: '/signatures',
    name: 'signatures',
    component: () => import('@/views/apps/publications/Signatures.vue'),
    meta: {
      layout: 'content',
      resource: 'Signature',
    },
  },
  {
    path: '/collecte',
    name: 'collecte',
    component: () => import('@/views/apps/publications/Collections.vue'),
    meta: {
      layout: 'content',
      resource: 'Collect',
    },
  },
  {
    path: '/assistance_texte',
    name: 'assistance_texte',
    component: () => import('@/views/apps/publications/AssistantText.vue'),
    meta: {
      layout: 'content',
      resource: 'Text',
    },
  },
  {
    path: '/assistance_couverture',
    name: 'assistance_couverture',
    component: () => import('@/views/apps/publications/AssistantCover.vue'),
    meta: {
      layout: 'content',
      resource: 'Cover',
    },
  },
  {
    path: '/supervision',
    name: 'supervision',
    component: () => import('@/views/apps/publications/Supervisor.vue'),
    meta: {
      layout: 'content',
      resource: 'Supervision',
    },
  },
  {
    path: '/assistance_publication',
    name: 'assistance_publication',
    component: () => import('@/views/apps/publications/AssistantPublication.vue'),
    meta: {
      layout: 'content',
      resource: 'Publication',
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/apps/publications/Payments.vue'),
    meta: {
      layout: 'content',
      resource: 'Paiment',
    },
  },
  {
    path: '/resiliations',
    name: 'resiliations',
    component: () => import('@/views/apps/publications/Resiliation.vue'),
    meta: {
      layout: 'content',
      resource: 'Resiliation',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/apps/publications/Report.vue'),
    meta: {
      layout: 'content',
      resource: 'Report',
    },
  },
  {
    path: '/communications',
    name: 'communications',
    component: () => import('@/views/apps/communications/Communications.vue'),
    meta: {
      layout: 'content',
      resource: 'Communication',
    },
  },
  {
    path: '/managers',
    name: 'managers',
    component: () => import('@/views/apps/managers/Managers.vue'),
    meta: {
      layout: 'content',
      resource: 'Manager',
    },
  },
  {
    path: '/labs',
    name: 'labs',
    component: () => import('@/views/apps/shops/Shops.vue'),
    meta: {
      layout: 'content',
      resource: 'Shop',
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/apps/campaigns/Campaigns.vue'),
    meta: {
      layout: 'content',
      resource: 'Campaign',
    },
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: () => import('@/views/apps/referrals/Referrals.vue'),
    meta: {
      layout: 'content',
      resource: 'Referral',
    },
  },
]

export default apps
