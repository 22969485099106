import {
  mdiBookAccountOutline,
  mdiCart,
  mdiBook,
  mdiBell,
  mdiStore,
  mdiAccountGroup,
  mdiAccountTie,
} from '@mdi/js'

export default [
  {
    subheader: 'Management',
  },
  {
    title: 'Products',
    icon: mdiBookAccountOutline,
    to: 'products',
    resource: 'Product',
    action: 'read',
  },
  {
    title: 'Carts',
    icon: mdiCart,
    to: 'carts',
    resource: 'Cart',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Orders',
    icon: mdiCart,
    to: 'orders',
    resource: 'Order',
    action: 'read',

    // badge: '3',
    // badgeColor: 'warning',
  },

  {
    title: 'Demands',
    icon: mdiBook,
    to: 'demands',
    resource: 'Demand',
    action: 'read',
  },
  {
    title: 'Tests',
    icon: mdiBook,
    to: 'tests',
    resource: 'Test',
    action: 'read',
  },
  {
    title: 'Labs',
    icon: mdiStore,
    to: 'labs',
    resource: 'Shop',
    action: 'read',
    // badge: '3',
    // badgeColor: 'warning',
  },
  {
    title: 'Managers',
    icon: mdiAccountGroup,
    to: 'managers',
    resource: 'Manager',
    action: 'read',
  },
  {
    title: 'Campaigns',
    icon: mdiBell,
    to: 'campaigns',
    resource: 'Campaign',
    action: 'read',
  },
  {
    title: 'Referrals',
    icon: mdiAccountTie,
    to: 'referrals',
    resource: 'Referral',
    action: 'read',
  },
]
